.u-bordered-18 {
    border-radius: rem(18);
}

.u-bordered-24 {
    border-radius: rem(24);
}

.u-shadow-l {
    box-shadow: 0 rem(53) rem(61.5) 0 #1B232814;
}

.u-underlined-bold {
    text-decoration-thickness: rem(2);
    text-underline-offset: rem(5);

    &:hover {
        text-decoration: none;
    }
}

.u-gradient-bg {
    position: relative;
    overflow: hidden;
    

    &__inner {
        position: relative;
        z-index: 3;
        box-shadow: inset -50px -165px 75px 0px #cfe8ee;

        @include mq(sm) {
            box-shadow: inset -50px -800px 35px 0px #cfe8ee;
        }
    }

    &--top {
        position: absolute;
        top: 0;
        right: -20%;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
    &--blue {
        @include mq(md-and-up) {
            background: hsla(218, 100%, 92%, 1);
            background: radial-gradient(circle, hsla(218, 100%, 92%, 1) 0%, hsla(216, 100%, 97%, 1) 100%);
            background: -moz-radial-gradient(circle, hsla(218, 100%, 92%, 1) 0%, hsla(216, 100%, 97%, 1) 100%);
            background: -webkit-radial-gradient(circle, hsla(218, 100%, 92%, 1) 0%, hsla(216, 100%, 97%, 1) 100%);
        }
    }
}
