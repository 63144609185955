.c-breadcumbs {
    font-size: rem(14);
    margin-top: rem(16);
    margin-bottom: rem(50);

    @include mq(md-and-up) {
        margin-bottom: rem(16);
    }

    svg {
        width: rem(7);
        height: rem(15);
        display: inline-block;
        vertical-align: bottom;
        margin-left: rem(3);
        margin-right: rem(8);
    }

    a {
        text-decoration: none;
        color: $color-text-secondary;
        transition: color .4s;

        @include mq(hover) {
            color: $color-red;
        }
    }

    .p-article & {
        @include mq(sm) {
            margin-bottom: rem(16);
        }
    }
}
