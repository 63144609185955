.c-questions-contact {
    padding-bottom: rem(48);

    @include mq(lg) {
        padding-bottom: rem(128);
    }

    &__title {
        padding-bottom: rem(20);
    }

    &__description {
        font-size: rem(20);
        line-height: rem(28);
        padding-bottom: rem(25);
        color: $color-text-secondary;

        @include mq(lg) {
            max-width: rem(500);
        }
    }

    picture {
        position: relative;
        margin-right: rem(80);
    }

    &__img {
        position: absolute;
        top: rem(20);
        right: 0;
        max-width: initial;
        width: 135%;

        @include mq(xlg) {
            width: 150%;
        }
    }

    &__list {
        margin-bottom: rem(40);
        border-bottom: rem(1) solid $color-gray2;

        li {
            padding-bottom: rem(40);
        }
        
        a {
            display: inline-block;
            text-decoration: none;

            @include mq(hover) {
                &:hover {
                    .c-questions-contact__list__icon {
                        background-color: $color-red;

                        svg {
                            stroke: $color-white;
                        }
                    }
                }
            }
        }
        
        &__icon {
            margin-right: rem(15);
        }

        &__title {
            @extend .u-font-secondary-bold;

            display: flex;
            flex-flow: row nowrap;
            align-items: flex-start;
            font-size: rem(24);
            line-height: rem(35);
        }

        &__description {
            display: inline-block;
            line-height: rem(24);
            color: $color-text-secondary;
            padding-left: rem(56);
        }
    }

    &__form-description {
        strong {
            font-size: rem(20);
        }

        p {
            color: $color-text-secondary;
            line-height: rem(24);
            padding-bottom: rem(16);

            @include mq(lg) {
                max-width: rem(500);
            }
        }
    }

    &__text {
        margin: 0;
        line-height: rem(24);
        color: $color-text-secondary;
    }

    &--secondary {
        border-top: rem(1) solid $color-gray2;
        padding-top: rem(24);

        @include mq(md-and-up) {
            padding-top: rem(32);
        }

        .c-questions-contact__list {
            border-bottom: none;
            margin-bottom: 0;

            &__title {
                @extend .u-font-primary;
                font-size: rem(16);
                color: $color-text-secondary;
                line-height: rem(20);
            }

            &__description {
                @extend .u-font-secondary-bold;
                position: relative;
                top: rem(-5);
                font-size: rem(20);
                color: $color-black;
            }
        }
    }
}