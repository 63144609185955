.o-btn {
    @extend .u-font-secondary;
    display: inline-block;
    padding: rem(15) rem(20);
    border: rem(2) solid $color-black;
    font-size: rem(20);
    border-radius: rem(4);
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    transition: background-color .5s, color .5s, border .3s;

    @include mq(sm) {
        padding: rem(15) rem(16);
    }
    
    @include mq(hover) {
        &:hover {
            background-color: $color-red;
            border-color: $color-red;
        }
    }

    &__primary {
        background-color: $color-black;
        color: $color-white;
    }

    &__secondary {
        background-color: transparent;
        color: $color-text-primary;

        @include mq(hover) {
            &:hover {
                color: $color-white;
            }
        }
    }

    &--full-width {
        width: 100%;
    }

    &--icon {
        @include mq(hover) {
            svg,
            path {
                transition: all 0.3s;
                fill: $color-white;
            }
        }
    }

    @include mq(sm) {
        width: 100%;
    }

    &--default-size-mobile {
        @include mq(sm) {
            width: auto;
        }
    }
}