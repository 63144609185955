fieldset {
    border: none;
    padding: 0;
    margin: 0 0 rem(14) 0;
}

.o-input-wrapper,
.o-textarea-wrapper {
    position: relative;
    margin-bottom: rem(24);

    label {
        position: absolute;
        top: 50%;
        left: rem(12);
        font-size: rem(16);
        color: $color-text-secondary;
        transform: translateY(-50%);
        transition: all .4s;
        transform-origin: left;
        cursor: pointer;
    }
}

.o-input,
.o-textarea {
    @extend .u-font-primary-bold;
    width: 100%;
    height: rem(64);
    padding: rem(18) rem(12) 0;
    border: rem(1) solid $color-gray2;
    border-radius: rem(4);
    transition: all .3s;
    cursor: pointer;

    &--icon-right {
        padding-right: rem(60);
    }

    &--required {
        position: relative;

        label {
            &::after {
                content: "•";
                color: $color-red;
                position: absolute;
                top: 50%;
                right: rem(-10);
                transform: translateY(-50%);
            }
        }
    }

    &--error {

        .o-input,
        .o-textarea {
            border: rem(1) solid $color-red;
            color: $color-red;

            &:hover {
                border-color: $color-red;
            }
        
            &:focus {
                border-color: $color-red;
                box-shadow: 0 0 0 rem(1) $color-red;
            }
        }
    }

    &--disabled {
        label {
            color: $color-disable-text;
        }

        .o-input,
        .o-textarea {
            background: $color-white;

            &:hover {
                border-color: $color-gray2;
            }
        }
    }

    &__error {
        font-size: rem(14);
        margin-top: rem(-10);
        color: $color-red;
        margin-bottom: rem(24);
    }

    &:hover {
        border-color: $color-green;
    }

    &:focus {
        box-shadow: 0 0 0 rem(1) $color-green;
        border-color: $color-green;
        outline: none;

        & ~ label {
            transform: translateY(-22px) scale(.75);
        }
    }

    &:not(:placeholder-shown) {
        & ~ label {
            transform: translateY(-22px) scale(.75);
        }
    }
}

.o-textarea {
    height: rem(128);
    padding-top: rem(28);

    &-wrapper {
        label {
            top: rem(28);
        }
    }
}

.o-radio {
    display: flex;
    position: relative;
    margin-bottom: rem(28);
    cursor: pointer;

    input[type="radio"] {
        appearance: none;
        cursor: pointer;
        width: rem(20);
        margin-right: rem(12);
    
        &:before {
            content: "";
            display: block;
            height: rem(20);
            width: rem(20);
            margin-right: rem(12);
            background: $color-black;
            border-radius: 100%;
            transition: box-shadow .4s;
        }

        &:after {
            content: '';
            position: absolute;
            left: rem(2);
            top: rem(2);
            width: rem(16);
            height: rem(16);
            background-color: $color-white;
            border-radius: 100%;
            transition: all .4s;
        }

        &:checked {
            &:after {
                transform: scale(0.5);
            }
        }
    }
}

.o-custom-select {
    background: $color-white;
    height: 64px;
    border: solid 1px $color-gray2;
    border-radius: 4px;
    padding-top: 10px;

    &__flag {
        width: rem(24);
        height: rem(24);
        margin: rem(8) rem(12);
        float: left;
    }

    &__label {
        display: block;
        font-size: rem(12);
        color: $color-gray4;
        margin-bottom: rem(6);
    }

    select {
        width: calc(100% - rem(65));
        border: none;
        -webkit-appearance: none;
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNyAxMSIgZmlsbD0iIzFCMjMyOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMS41MDAxNSAwLjU4NTkzOEw4LjUwMDE1IDcuNTg1OTRMMTUuNTAwMiAwLjU4NTkzOEwxNi45MTQ0IDIuMDAwMTVMOC41MDAxNSAxMC40MTQ0TDAuMDg1OTM3NSAyLjAwMDE1TDEuNTAwMTUgMC41ODU5MzhaIi8+PC9zdmc+Cg==) no-repeat;
        background-position: 100% top !important;
        outline: none;
    }
}
