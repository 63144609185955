.c-cpa-solutions {
    margin-bottom: rem(56);

    @include mq(md-and-up) {
        margin-bottom: rem(106);
    }

    h2 {
        position: relative;
        max-width: rem(700);
        margin: 0 auto;

        &:after {
            content: '';
            display: block;
            margin: rem(32) auto;
            width: rem(152);
            height: rem(1);
            background: $color-gray2;
        }
    }

    h6 {
        margin-bottom: rem(48);

        @include mq(md-and-up) {
            margin-bottom: rem(80);
        }
    }

    &__item {
        padding: rem(40) rem(40) rem(12);
        border-radius: rem(24);
        box-shadow: 0px 53px 61.5px 0px #1B232814;
        background: $color-white;

        @include mq(sm) {
            margin-bottom: rem(24);
        }

        @include mq(md-and-up) {
            margin-left: rem(32);

            &:first-child {
                margin-left: 0;
            }
        }

        &__img {
            display: flex;
            justify-content: center;
            align-items: center;
            height: rem(134);
            margin-bottom: rem(34);
            border-radius: rem(16);
    
            svg {
                height: 100%;
            }
        }

        a {
            width: 100%;
        }

        p {
            color: $color-text-secondary;
            line-height: rem(24);
            margin-bottom: rem(32);
        }
    }
}