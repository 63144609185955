@use "sass:math";

.quick-start {
    margin-bottom: rem(88);
    
    @include mq(md-and-up) {
        margin-bottom: rem(128);
    }

    &__steps {
        padding: rem(56) rem(48);
        margin-bottom: rem(32);
        text-align: center;

        @include mq(md-and-up) {
            margin-top: rem(56);
            margin-bottom: rem(56);
        }

        h5 {
            margin: rem(38) 0 0;
            padding: 0 rem(18);
        }
    }

    svg {
        width: 26%;
        max-width: rem(80);
        height: auto;
        transition: all .4s;

        &.quick-start__step--green {
            fill: $color-green;
        }
        &.quick-start__step--orange {
            fill: $color-orange;
        }
        &.quick-start__step--red {
            fill: $color-red;
        }
        &.quick-start__step--accent-blue {
            fill: $color-accent-blue;
        }
    }

    &__step {
        position: relative;
        margin-top: rem(45);
        padding-bottom: rem(28);

        @include mq(md-and-up) {
            margin: 0;
            padding: 0;
        }

        &:last-child {
            padding-bottom: 0;
            &:before,
            &::after {
                display: none;
            }
        }

        &:first-child {
            margin-top: 0;
        }

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            right: 50%;
            transform: translateX(50%);
            background: $color-gray2;
            display: inline-block;
            height: rem(1);
            width: 54%;

            @include mq(md-and-up) {
                width: rem(1);
                right: 0;
                transform: translateX(0);
                height: 100%;
            }
        }

        &:after {
            content: '';
            position: absolute;
            bottom: rem(-3);
            right: 49%;
            border: solid $color-gray2;
            border-width: 0 rem(1) rem(1) 0;
            display: inline-block;
            padding: rem(12);
            background: $color-white;
            transform: rotate(45deg) translateX(50%);

            @include mq(md-and-up) {
                width: rem(1);
                right: rem(-20);
                bottom: calc(50% - math.div(rem(35), 2));
                transform: translateX(0);
                transform: rotate(-45deg) translateY(-50%);
            }
        }

        &:not(:hover) {
            svg {
                @include mq(md-and-up) {
                    fill: $color-gray2;;
                }
            }
        }
    }
}