.c-text-section {
    margin-bottom: rem(56);
    padding: rem(32) rem(24);

    @include mq(md-and-up) {
        margin-bottom: rem(128);
        padding: rem(56) rem(24);
    }

    h2 {
        margin-bottom: rem(32);
    }

    p {
        max-width: rem(700);
        margin: rem(32) auto;
        font-size: rem(20);
        line-height: rem(28);
        color: $color-text-secondary;
    }
}