.c-comparison {
    gap: rem(32);

    &__item {
        background: $color-white;
        padding: rem(40);
        border-radius: rem(24);
        box-shadow: 0 rem(53) rem(61.5) 0 rgba(27, 35, 40, 0.08);

        @include mq(md-and-up) {
            width: calc(50% - rem(32));
        }

        @include mq(sm) {
            margin-bottom: rem(20);
        }
    }

    &__image {
        display: inline-block;
        padding: rem(20);
        border-radius: rem(16);
        margin-bottom: rem(32);
    }

    h4 {
        @include mq(sm) {
            font-size: rem(22);
        }
    }
}