.c-stat-info {
    padding: rem(58) 0;
    position: relative;

    @include mq(sm) {
        padding: rem(1) 0;
    }

    &__item {
        background: $color-white;
        border-radius: rem(18);
        padding: rem(35) 0;
        text-align: center;
        box-shadow: 0px 6.65px 5.32px 0px rgba(27, 35, 40, 0.03), 0px 2.767px 2.214px 0px rgba(27, 35, 40, 0.02);

        @include mq(md-and-up) {
            width: 31%;
        }

        @include mq(sm) {
            margin: rem(20) 0;
        }
    }

    &__number {
        @extend .u-font-primary-bold;
        
        letter-spacing: rem(-3);
        font-size: rem(64);
        color: $color-black; 
    }

    &__item-description {
        font-size: rem(34);
        margin: 0;
        background: #B3B4BD;
        background: linear-gradient(to right, #B3B4BD 0%, #3F3F44 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}