@mixin accordion() {
    .c-accordion {
        &__item {
            position: relative;
            cursor: pointer;
        }

        &__content {
            width: 100%;
            background: $color-white;
            position: static;
            max-height: 0;
            overflow: hidden;
            visibility: visible;
            opacity: 1;
            transition: max-height .4s;
        }
    }
}

@mixin accordion-arrow() {
    .c-accordion {
        &--arrow {
            position: absolute;
            height: rem(15);
            width: rem(15);
            top: rem(36);
            right: rem(10);
            z-index: -1;
            font-size: 0;
            
            &::after,
            &::before {
                content: '';
                position: absolute;
                top: 0;
                width: rem(9);
                height: rem(2);
                background-color: $color-black;
                transition: .3s all;
            }
    
            &::after {
                right: rem(0);
                transform: rotate(-45deg);
            }
    
            &::before {
                right: rem(5);
                transform: rotate(45deg);
            }
        }
    }

    .accordion-item-active {
        .c-accordion--arrow {
            &::after {
                transform: rotate(45deg);
            }
    
            &::before {
                transform: rotate(-45deg);
            }                
        }
    }
}