.c-info-section {
    display: flex;
    flex-flow: column-reverse wrap;
    align-items: center;
    margin-bottom: rem(40);

    @include mq(md-and-up) {
        flex-flow: row nowrap;
        margin-bottom: rem(80);
    }

    &--custom-margin-0 {
        margin-bottom: 0;
    }

    &--custom-margin {
        @include mq(md-and-up) {
            margin-bottom: rem(88);
        }
    }
    
    &--custom-margin__big {
        @include mq(md-and-up) {
            margin-bottom: rem(108);
        }
    }

    &--custom-margin__small {
        @include mq(md-and-up) {
            margin-bottom: rem(36);
        }
    }

    &--custom-margin-top {
        @include mq(md-and-up) {
            margin-top: rem(80);
        }
    }

    
    &--img-left {
        @include mq(sm) {
            flex-flow: column nowrap;
        }

        .c-info-section__img {
            @include mq(md-and-up) {
                padding-right: rem(40);
            }
        }
    }
    
    &--equal-content {
        @include mq(md-and-up) {
            .c-info-section__content {
                width: 50%;
            }
            
            .c-info-section__img {
                width: 50%;
            }
        }
    }

    &--dominance-content {
        @include mq(md-and-up) {
            .c-info-section__content {
                width: 58%;
            }
            
            .c-info-section__img {
                width: 42%;
            }
        }
    }

    &__content {
        @include mq(sm) {
            padding-top: rem(40);
        }

        @include mq(md-and-up) {
            width: 40%;
        }

        &__title {
            margin-bottom: rem(32);
        }

        &__subtitle {
            line-height: rem(24);
            margin-bottom: rem(32);
        }

        &__text {
            margin: 0 0 rem(32);
            line-height: rem(24);
            color: $color-text-secondary;

            &--m-0 {
                margin: 0;
            }
        }

        &__link-wrapper {
            display: block;
            padding-top: rem(16);
        }

        &__link {
            text-underline-offset: rem(5);
            text-decoration-thickness: rem(2);
            transition: color .3s;

            &:hover {
                color: $color-red
            }
        }

        &__img {
            margin-top: rem(32);
            
            @include mq(md-and-up) {
                margin-top: rem(56);
                max-width: 85%;
            }
        }
    }

    &__img {
        @include mq(sm) {
            width: 100%;
            text-align: center;
        }

        &--negative-top {
            position: relative;
            top: rem(-35);
        }
    }

    &__list {
        margin-top: rem(56);
    }

    &__double-btns {
        display: flex;
    }

    .c-hero-banner__list {
        padding-top: rem(56);
    }

    &__banner-images,
    &__ratings {
        display: flex;
        justify-content: space-between;
    }

    &__banner-images {
        @include mq(sm) {
            flex-direction: column;
        }
    }

    &__ratings {
        margin: 0;
        justify-content: flex-end;
        gap: rem(16);

        @include mq(sm) {
            min-width: rem(420);
            li {
                padding: 15px;
            }
        }

        &--outer {
            flex: 1;
            overflow: auto;
        }
    }
}