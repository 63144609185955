body {
    @extend .u-font-primary;
    color: $color-black;
}

main {
    padding-top: rem(65);

    @include mq(lg) {
        padding-top: rem(104);
    }
}

.c-main-window-top {
    padding-top: 0;
}

a {
    color: $color-text-primary;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

h1 {
    @extend .u-font-secondary-bold;
    font-size: rem(40);
    
    @include mq(lg) {
        font-size: rem(64);
    }
}

.h1--custom {
    @include mq(md-and-down) {
        font-size: rem(64);
    }
}

h2 {
    @extend .u-font-secondary-bold;
    font-size: rem(40);
    line-height: rem(50);
    
    @include mq(lg) {
        font-size: rem(50);
        line-height: rem(62.5)
    }
}

.h2--custom {
    margin-bottom: rem(56);

    @include mq(md-and-up) {
        margin-bottom: rem(95)
    }
}

h3 {
    @extend .u-font-secondary-bold;
    font-size: rem(30);
    
    @include mq(lg) {
        font-size: rem(40);
    }
}

h4 {
    @extend .u-font-secondary-bold;
    font-size: rem(32);
    line-height: rem(43);
}

h5 {
    @extend .u-font-secondary-bold;
    font-size: rem(24);
    line-height: rem(34);
}

h6 {
    @extend .u-font-primary;
    font-size: rem(28);
    line-height: rem(35);
}

img,
picture {
    max-width: 100%;
    height: auto;
    line-height: 0;
}

.gradient {
    background: linear-gradient(28.34deg, #FFFFFF 63.69%, #E0EFF8 100%);;
}

.hr-decor {
    display: flex;
    line-height: rem(8);
}

.circle-with-icon {
    display: inline-block;
    width: rem(40);
    min-width: rem(40);
    height: rem(40);
    position: relative;
    border: 0;
    border-radius: 100%;
    background-color: rgba($color-green, 0.15);
    transition: all .5s;

    svg {
        position: absolute;
        left: 50%;
        top: 50%;
        stroke: $color-green;
        transform: translate(-50%, -50%);
        transition: all .5s;
    }

    &--hovered {
        &:hover,
        &:focus {
            background-color: $color-red;

            svg {
                stroke: $color-white;
            }   
        }
    }
}

.block-shadow {
    box-shadow: 0px 32px 32px rgba(12, 62, 57, 0.07);
}

.c-info-title {
    @extend .u-font-secondary;

    font-size: 16px;
    text-transform: uppercase;
    color: $color-blue-light;
    margin-bottom: rem(16);
    line-height: rem(24);
}

.shrink-2 {
    flex-shrink: 2;
}

.flex-1 {
    flex: 1;
}

.u-decor-underline {
    position: relative;
    white-space: nowrap;

    svg {
        margin-bottom: 0 !important;
        position: absolute;
        bottom: rem(-5);
        left: 0;
    }
}
