/* ==========================================================================
   # Colors and backgrounds utilities
   ========================================================================== */

.u-color-white { color: $color-white; }
.u-color-black { color: $color-black; }
.u-color-bg-white { background-color: $color-white; }
.u-color-bg-black { background-color: $color-black; }

.u-color-blue {color: $color-blue;}
.u-color-green {color: $color-green;}
.u-color-orange {color: $color-orange;}
.u-color-red {color: $color-red;}
.u-color-grey4 {color: $color-gray4;}
.u-color-accent-blue {color: $color-accent-blue;}

.u-color-bg-blue {background-color: $color-blue;}
.u-color-bg-green {background-color: $color-green;}
.u-color-bg-card-green {background: $color-green-x-light;}
.u-color-bg-card-blue {background: $card-blue;}
.u-color-bg-card-yellow {background: $card-yellow;}
.u-color-bg-card-red {background: $card-red;}
.u-color-bg-orange {background-color: $color-orange;}
.u-color-bg-red {background-color: $color-red;}
.u-color-bg-accent-blue {background-color: $color-accent-blue;}
.u-color-bg-section-blue {background-color: $color-section-blue;}

.u-color-bg-gray1 {background-color: $color-gray1;}
.u-color-text-secondary {color: $color-gray4;}
