.c-merchants {
    &__carousel {
        opacity: 0.5;

        @include mq(md-and-up) {
            padding: 0 rem(110);
        }

        .swiper-wrapper {
            padding: rem(25) 0;
            align-items: center;
        }

        .circle-with-icon {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: rem(56);
            height: rem(56);
            cursor: pointer;
        }
    }
}