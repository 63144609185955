.c-header {
    width: 100%;
    background: transparent;   
    position: fixed;
    top: rem(0);
    z-index: 4;
    
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 1;
        filter: none;
        transition: all .3s;
    }

    &:hover {
        &::after {
            background: $color-white;
        }
    }

    &.header-stuck {
        &::after {
            background: $color-white;
        }
    }

    @include mq(md-and-down) {
        @include social-partner-devices;
    }

    @include mq(lg) {
        backdrop-filter: blur(8px);
        -webkit-backdrop-filter: blur(8px);

        &::after {
            opacity: 0.9;
            filter: blur();
        }

        &:hover {
            &::after {
                opacity: 1;
                filter: none;
                backdrop-filter: blur(0);
            }
        }
    }

    &__logo {
        width: rem(170);

        @include mq(xlg) {
            width: rem(200);
        }

        img {
            display: block;
        }
    }

    &__inner {
        padding: 0 rem(20);
        height: rem(65);

        @include mq(lg) {
            height: rem(96);
        }

        @media screen and (max-width: 1440px) {
            margin: 0 auto;
        }

        @media screen and (min-width: 1440px) {
            padding: 0 5%;
        }
    }

    &__nav {
        @include mq(md-and-down) {
            display: block;
            width: 100%;
            margin: rem(24) 0 0;

            & > li {
                &:first-child {
                    a {
                        border: none;
                    }
                }
            }
        }
    }

    &__nav__item {
        position: relative;

        @include mq(md-and-down) {
            &:last-child {
                border-bottom: 1px solid $color-gray2;
            }
        }

        @include mq(lg) {
            margin: 0 rem(10);

            .nav-item-hovered & {
                .c-header__nav__item-link {
                    opacity: 0.5;
                }
    
                &:hover {
                    .c-header__nav__item-link {
                        opacity: 1;
                    }
                }
            }

            &:hover {
                .c-header__subnav {
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0);
                }
            }
        }

        @include mq(xlg) {
            margin: 0 rem(16);
        }

        a {
            text-decoration: none;
        }

        &:hover {
            .c-header__nav__item-link--arrow {
                &::after {
                    transform: rotate(45deg);
                }
        
                &::before {
                    transform: rotate(-45deg);
                }                
            }
        }
    }

    &__nav__item-link {
        font-size: rem(16);
        padding-bottom: rem(38);
        transition: opacity .3s;
        cursor: pointer;

        @include mq(lg) {
            margin-right: rem(22);  
        }

        @include mq(xlg) {
            font-size: rem(19);
        }

        @include mq(md-and-down) {
            width: 100%;
            display: inline-block;
            padding: rem(20);
            border-top: 1px solid $color-gray2;
            font-size: rem(24);
            font-weight: 700;
        }

        &--arrow {
            position: absolute;
            width: rem(15);
            right: 0;

            @include mq(md-and-down) {
                top: rem(12);
                right: rem(20);
                width: rem(44);
                height: rem(44);
                border-radius: 100%;
                background-color: rgba($color-green, 0.15);
                transition: all .5s;
                z-index: -1;
            }

            &::after,
            &::before {
                content: '';
                position: absolute;
                top: rem(20);
                width: rem(9);
                height: rem(3);
                background-color: $color-gray3;
                transition: .3s all;

                @include mq(md-and-down) {
                    background-color: $arrow-green;
                }

                @include mq(lg) {
                    top: rem(10);
                    height: rem(2);
                }

                @include mq(xlg) {
                    top: rem(12);
                }
            }

            &::after {
                right: rem(15);
                transform: rotate(-45deg);

                @include mq(lg) {
                    right: rem(0);
                }
            }

            &::before {
                right: rem(20);
                transform: rotate(45deg);

                @include mq(lg) {
                    right: rem(5);
                }
            }
        }
    }

    &__subnav {
        position: absolute;
        width: rem(275);
        top: rem(70);
        left: 0;
        background: $color-white;
        transition: .3s all;

        @include mq(lg) {
            transform: translateY(rem(-10));
            visibility: hidden;
            opacity: 0;
            border-radius: rem(8);

            &::before {
                content: '';
                width: 100%;
                height: rem(20);
                position: absolute;
                left: 0;
                top: rem(-20);
            }
        }

        &__item {
            @include mq(md-and-down) {
                &:nth-child(even) {
                    background-color: $color-gray0;
                }
            }
            
            @include mq(lg) {
                border-bottom: 1px solid $color-gray2;
            }

            &:last-child {
                border: none;
            }
        }

        &__item-link {
            display: inline-block;
            width: 100%;
            padding: rem(20) rem(36);

            @include mq(sm) {
                font-size: rem(20);
            }

            &:hover {
                background-color: $color-gray1;
            }
        }
    }

    &__utility-nav {
        @include mq(md-and-down) {
            width: 100%;
            order: -1;
            padding: 0 rem(10);
            justify-content: space-between;
            flex-direction: column;

            > li {
                width: 100%;
                padding: 0 rem(10);
            }
        }

        &__button {
            font-size: rem(14);

            @include mq(xlg) {
                font-size: rem(20);
            }
            
            @include mq(lg) {
                margin-right: rem(24);
            }
    
            @include mq(md-and-down) {
                width: 100%;
            }
        }
    }

    &__utility-nav__link {
        font-size: rem(14);
        text-decoration: none;
        transition: color .3s;
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        height: rem(56);

        @include mq(xlg) {
            font-size: rem(20);
        }

        @include mq(md-and-down) {
            justify-content: center;
            margin: 0 auto;
        }

        @include mq(hover) {
            &:hover {
                color: $color-red;
            }
        }
    }

    &__account-locale {
        height: 56px;
        align-items: center;
        background: $color-white;
        display: flex;
        
        @include mq(lg) {
            padding: rem(12) rem(14);
            border-radius: rem(8);
            box-shadow: 0 rem(5) rem(13) 0 rgba(0, 0, 0, 0.04);
        }

        @include mq(md-and-down) {
            justify-content: space-between;
            margin-top: rem(15);
        }

        li {
            @include mq(md-and-down) {
                border: rem(2) solid $color-black;
                border-radius: rem(8);
                width: 27%;
            }

            &:first-child {
                @include mq(lg) {
                    border-right: 1px solid $color-gray2;
                    padding-right: rem(12);
                    margin-right: rem(12);
                }

                @include mq(md-and-down) {
                    width: 70%;
                }
                
            }
        }

        svg {
            margin: 0 rem(5);
        }
    }

    &__hamburger-btn {
        background: none;
        border: 0;
        height: rem(40);
        width: rem(40);
        transition: all .3s;

        &__bar {
            height: rem(3);
            width: rem(28);
            margin: 5px auto;
            background-color: $color-black;
            transition: all .4s;
        }

        .mobile-menu-opened & {
            .c-header__hamburger-btn__bar {
                &:nth-of-type(1) {transform : translateY(10px) rotate(45deg);}
                &:nth-of-type(2) {opacity: 0;}
                &:nth-of-type(3){transform : translateY(-6px) rotate(-45deg);}
            }
        }
    }

    &__hamburger-menu {        
        top: rem(65);
        width: 100%;
        transition: transform 0.4s;

        @include mq(md-and-down) {
            display: flex;
            flex-flow: column nowrap;
            position: absolute;
            right: 0;
            height: calc(100vh - rem(65));
            transform: translateX(100%);
            background: $color-white;
            overflow-y: scroll;
            
            .mobile-menu-opened & {
                transform: translateX(0);    
            }
        }
    }
}

@include mq(md-and-down) {
    .mobile-menu-opened {
        .c-footer,
        main {
            transition: all .3s;
            transform: translateX(-100%);
        }
    }

    .c-header {
        @include accordion;

        .accordion-item-active {
            color: $color-white;
            position: relative;
            border: none;

            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: $color-green;
                z-index: -1;
            }

            .c-header__nav__item-link--arrow {
                background-color: $color-green-light;
                &::after,
                &::before {
                    background-color: $color-white;
                }
                &::after {
                    transform: rotate(45deg);
                }
        
                &::before {
                    transform: rotate(-45deg);
                }     
            }
        }
    }
    
}
