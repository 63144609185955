.c-benefits {
    margin-bottom: rem(56);

    @include mq(md-and-up) {
        margin-bottom: rem(72);
    }

    &__list {
        margin: 0;
    }

    h2 {
        margin-bottom: rem(48);

        @include mq(md-and-up) {
            margin-bottom: rem(72);
        }
    }

    &__item {
        position: relative;
        margin-bottom: rem(32);

        @include mq(md-and-up) {
            width: 49%;
            margin-bottom: rem(56);
        }

        &:hover {
            .c-benefits__item__deco {
                opacity: 1;
            }
        }

        &__deco {
            width: 26%;
            padding: 0 rem(8);
            border-radius: rem(4) 0 0 rem(4);
            transition: all .4s;

            @include mq(md-and-up) {
                width: 34%;
                opacity: 0.4;
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 26%;
                width: 74%;
                height: 100%;
                border: rem(1) solid $color-green;
                border-radius: 0 rem(4) rem(4) 0;
                z-index: -1;

                @include mq(md-and-up) {
                    left: 34%;
                    width: 66%;
                }
            }

            &.u-color-bg-orange {
                &::after {
                    border-color: $color-orange;
                }
            }

            &.u-color-bg-red {
                &::after {
                    border-color: $color-red;
                }
            }

            &.u-color-bg-accent-blue {
                &::after {
                    border-color: $color-accent-blue;
                }
            }

            svg {
                position: absolute;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;

                @include mq(sm) {
                    top: rem(40);
                    transform: translate(-50%, 0);
                    height: rem(50);
                    width: auto;
                }

                @include mq(md-and-up) {
                    top: 50%;
                    
                }
            }
        }

        &__deco-wrapper {
            height: 100%;
            position: relative;
        }

        &__content {
            padding: rem(32);
            width: 74%;

            @include mq(md-and-up) {
                padding: rem(44) rem(40);
                width: 64%;
            }
        }

        &--custom {
            padding: rem(40) rem(24);
            box-shadow: 0px 32px 32px rgba(12, 62, 57, 0.07);

            @include mq(md-and-up) {
                padding: rem(44) rem(100);
            }

            h5 {
                margin-bottom: rem(32);
            }
        }
    }

    &--steps {
        .c-benefits {
            &__item__deco {
                width: rem(85);

                &:after {
                    width: calc(100% - rem(85));
                    left: rem(85);
                }
            }

            &__item__content {
                width: calc(100% - rem(85));
            }

            &__item__deco-wrapper {
                padding-top: rem(33);
                text-align: center;
            }

            &__item__step-num {
                font-size: rem(32);
                color: $color-white;
            }
        }
    }

    &__new {
        margin: 0 rem(-13);

        h5 {
            font-size: rem(22);
        }

        li {
            padding-inline: rem(13);
            margin-bottom: rem(30);

            @include mq(md-and-up) {
                margin-bottom: rem(40);
            }
        }

        &__img {
            height: rem(180);
            display: grid;
            align-items: center;
            justify-content: center;
            margin-bottom: rem(30);
            background: $card-blue;
            border-radius: rem(18);
        }

        &__content {
            padding-inline: rem(8);
        }
    }
}