.c-custom-carousel {
    overflow-x: hidden;

    &__inner {
        padding-left: calc((100% - $base-container-width) / 2);
        overflow-x: auto;
    }

    &__item {
        padding: 0 rem(16);
        min-width: rem(380);
        max-width: rem(380);

        &--1\/3 {
            min-width: rem(425);
            max-width: rem(425);
        }
    }

    /* width */
    ::-webkit-scrollbar {
        display: none;
      }
}
