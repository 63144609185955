.c-compare-section {
    h2 {
        margin-bottom: rem(32);

        @include mq(md-and-up) {
            margin-bottom: rem(72);
        }
    }

    &__list {
        margin-top: 0;
    }

    &__sticky {
        position: sticky;
        top: rem(65);
        height: rem(60);
        background-color: rgba($color-black, .8);
        text-align: center;
        color: $color-white;
        backdrop-filter: blur(rem(4));

        @include mq(md-and-up) {
            display: none;
        }

        &__logo {
            align-self: center;
            transition: opacity .4s;
        }

        p {
            margin: 0;
            font-size: rem(14);
            align-self: center;

            span {
                display: block;
                padding: 0 rem(20);
            }
        }
    }

    &__item:first-child {
        .c-compare-section__title__logo,
        p {
            opacity: 1;
            transform: translateX(0);
        }
    }

    &__title {
        height: rem(66);
        background-color: $color-gray1;

        @include mq(md-and-up) {
            height: rem(91);
        }

        &__logo {
            padding-left: rem(20);
            opacity: 0;
            transform: translateX(rem(-30));
            transition: all .3s;
    
            @include mq(sm) {
                display: none;
            }
    
            @include mq(md-and-up) {
                padding-left: rem(32);
            }
        }     
    
        p {
            text-align: right;
            padding-right: rem(20);
            font-size: rem(20);
            opacity: 0;
            transform: translateX(rem(30));
            transition: all .3s;
    
            @include mq(sm) {
                display: none;
            }
    
            @include mq(md-and-up) {
                padding-right: rem(32);
            }
        }

        &.active-title {
            .c-compare-section__title__logo,
            p {
                opacity: 1;
                transform: translateX(0)
            }
        }

        &__name {
            @extend .u-font-secondary-bold;
            font-size: rem(26);
            text-align: center;

            @include mq(md-and-up) {
                font-size: rem(32);
            }
        }
    }

    &__separator {
        width: rem(3);
        background: rgba($color-gray2, .4);
    }

    &__shoparize {
        @extend .u-font-primary-bold;
        padding: rem(24) rem(16) rem(24) 0;
        align-self: center;
        line-height: rem(24);

        @include mq(md-and-up) {
            padding: rem(56) rem(64) rem(56) rem(32);
        }
    }

    &__other {
        padding: rem(24) 0 rem(24) rem(16);
        align-self: center;
        line-height: rem(24);
        color: $color-text-secondary;

        @include mq(md-and-up) {
            padding: rem(56) rem(32) rem(56) rem(64);
        }
    }
}