.c-pricing {
    margin-top: rem(32);

    &__subtitle {
        font-size: rem(18);
        line-height: rem(24);
        max-width: rem(460);
        margin: rem(24) auto;

        @include mq(md-and-up) {
            font-size: rem(20);
            line-height: rem(28);
        }
    }

    &__promo {
        @extend .u-font-primary-bold;
        width: rem(235);
        margin: 0 auto rem(61);
        padding: rem(12) rem(15);
        border: rem(1) solid $color-red;
        border-radius: rem(50);
        font-size: rem(20);
    }

    &__switcher {
        margin-bottom: rem(32);

        @include mq(md-and-up) {
            margin-bottom: rem(48);
        }
    }

    &__switcher-tool {
        margin: 0 rem(24);
    }

    &__switcher-value {
        @extend .u-font-primary-bold;
        font-size: rem(20);
        color: $color-disable-text;
        transition: color .4s;

        @include mq(md-and-up) {
            font-size: rem(24);
        }
    }

    &__plans {
        margin-bottom: rem(32);

        @include mq(md-and-down) {
            display: block;
        }

        @include mq(md-and-up) {
            margin-bottom: rem(48);
        }
    }

    &__plan {
        display: flex;
        padding: rem(24);
        border: rem(1) solid $color-gray2;
        border-radius: rem(3);

        @include mq(sm) {
            margin-bottom: rem(32);
        }

        @include mq(md-and-up) {
            padding: rem(32);
        }

        h3 {
            font-size: rem(38);
            padding-bottom: rem(32);
            margin-bottom: rem(32);
            border-bottom: rem(1) solid $color-gray2;
        }

        &__info {
            height: 100%;
        }

        &__label {
            display: inline-block;
            vertical-align: top;
            height: rem(38);
            margin-top: rem(5);
            padding: rem(8) rem(16);
            font-size: rem(16);
            line-height: rem(20);
            border-radius: rem(40);
            background: $color-red;
            color: $color-white;
            
            svg {
                display: inline-block;
                vertical-align: text-bottom;
                margin: 0 0 0 rem(5);
            }
        }

        &__list {
            border-bottom: rem(1) solid $color-gray2;
            padding-bottom: rem(20);
            margin-bottom: rem(32 );
        }

        &__custom-item {
            margin-top: rem(32);
            color: $color-gray4;
            
            &:before {
                content: none !important;
            }
        }

        li {
            padding-right: rem(40);
        }

        &--highlighted {
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08), 0px 20px 32px rgba(0, 0, 0, 0.12);
        }

        &--right {
            @include mq(xlg-and-down) {
                .c-pricing__tooltip-content {
                    right: rem(-10);
                    left: auto;

                    &:before {
                        right: rem(12);
                    }
                }
            }
            
        }
    }

    &__tooltip-icon {
        position: absolute;
        right: 0;
        left: auto;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 1;
        transition: all .4s;

        &:hover,
        &:active {
            z-index: 2;
            .c-pricing__tooltip-content {
                opacity: 1;
                visibility: visible;
            }
        }        
    }

    &__tooltip-content {
        position: absolute;
        width: rem(240);
        background: $color-white;
        padding: rem(10) rem(15);
        right: rem(-10);
        top: calc(100% + rem(12));
        opacity: 0;
        visibility: hidden;
        box-shadow: 0 0 rem(15) 0 $color-gray2;
        transition: all .4s;
        z-index: 2;

        @include mq(md-and-up) {
            right: auto;
            left: rem(-12);
            
        }

        &:before {
            content: '';
            position: absolute;
            top: rem(-7);
            width: rem(15);
            height: rem(15);
            background: $color-white;
            transform: rotate(45deg);

            @include mq(sm) {
                right: rem(12);
            }
        }

        p {
            @extend .u-font-primary;
            font-size: rem(14);
            line-height: rem(20);
            padding: 0;
            margin-top: rem(8);
        }
    }

    &__price {
        
        @include mq(md-and-up) {
            position: sticky;
            bottom: rem(10);
            background: $color-white; 
            box-shadow: 0px -10px 35px 21px #fff;
            z-index: 1;
        }

        &__inner {
            margin-bottom: rem(12);
        }
        
        &__currency {
            @extend .u-font-primary-bold;
            font-size: rem(36);
            
        }

        &__amount {
            @extend .u-font-primary-bold;
            font-size: rem(76);
            line-height: rem(68);
        }

        &__period {
            color: $color-gray4;
            align-self: flex-end;
            line-height: rem(30);
        }

        &__extra-shop {
            @extend .u-font-primary-bold;
        }

        p {
            color: $color-gray4;
            margin: rem(12) 0 0;
        }

        a {
            margin-top: rem(32);
        }
    }

    &__tax-notification {
        margin-bottom: rem(48);

        @include mq(md-and-up) {
            margin-bottom: rem(96);
        }
    }
}

.annualy-plan-active {
    .c-pricing {
        &__switcher-value--annual {
            color: $color-black;
        }

        &__plan__label--annually,
        &--annually {
            opacity: 1;
            visibility: visible;
            transition: all .4s;
        }

        &--annually {
            max-height: rem(50);
        }

        &__price__amount--monthly {
            display: none;
        }
    }
}

.monthly-plan-active {
    .c-pricing {
        &__switcher-value--monthly {
            color: $color-black;
        }

        &__plan__label--annually,
        &--annually {
            opacity: 0;
            visibility: hidden;
            transition: all .4s;
        }

        &__price__amount--annually {
            display: none;
        }

        &__plan__label--annually {
            transform: translateX(rem(15));

            @include mq(sm) {
                display: none;
            }
        }

        &--annually {
            max-height: 0;
            transform: translateY(rem(-5));
        }
    }
}